import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import RedirectLink from '../atoms/RedirectLink';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyItems: 'center',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.links}>
        <Typography variant="body1" className="highlighted">Institucional</Typography>

        <RedirectLink href="/quem-somos/">Quem somos</RedirectLink>
        <RedirectLink href="https://conube.com.br/blog/" toNewTab>Blog</RedirectLink>
        <RedirectLink href="https://atracaodetalentos.totvs.app/carreiramindgroup/extended#listJobOpportunities" toNewTab>Trabalhe conosco</RedirectLink>
        <RedirectLink href="/mapa-do-site/">Mapa do site</RedirectLink>
      </Box>

      <Box className={classes.links}>
        <Typography variant="body1" className="highlighted">Serviços</Typography>

        <RedirectLink href="/abrir-empresa/">Abrir empresa</RedirectLink>
        <RedirectLink href="/trocar-contador/">Trocar de contador</RedirectLink>
        <RedirectLink href="/quanto-custa/">Quanto custa?</RedirectLink>
      </Box>

      {/* <Box className={classes.links}>
        <Typography variant="body1" className="highlighted">Segurança</Typography> */}

        {/* TODO: Colocar link do Registro no CRC */}
        {/* <RedirectLink href="/" toNewTab>Registro no CRC</RedirectLink> */}
        {/* TODO: Colocar link da Política de privacidade */}
        {/* <RedirectLink href="/" toNewTab>Política de privacidade</RedirectLink> */}
        {/* TODO: Colocar link dos Termos de uso */}
        {/* <RedirectLink href="/" toNewTab>Termos de uso</RedirectLink> */}
      {/* </Box> */}
    </Box>
  );
};

export default Footer;
