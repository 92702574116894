import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  makeStyles,
} from '@material-ui/core';

import IconArrow from '../atoms/IconArrow';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,

    '& .MuiListItem-button': {
      borderRadius: '10px',
    },
  },
  name: {
    '& .MuiTypography-root': {
      color: `${theme.palette.secondary.contrastText} !important`,
      fontWeight: 'bold',
    },

    '& .MuiListItemIcon-root': {
      justifyContent: 'flex-end',

      '& svg path': {
        stroke: theme.palette.secondary.contrastText,
      },
    },

    '& .up svg': {
      transform: 'rotate(180deg)',
      transition: '0.1s',
    },

    '& .down svg': {
      transform: 'rotate(0deg)',
      transition: '0.1s',
    },
  },
  item: {
    paddingLeft: '30px',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [isCollapseOpen, setIsCollapseOpen] = useState('');

  const handleClick = (value) => {
    if (isCollapseOpen === value) {
      setIsCollapseOpen('');
    } else {
      setIsCollapseOpen(value);
    }
  };

  const renderOption = (name, items) => (
    <>
      <ListItem
        button
        onClick={() => handleClick(name)}
        className={classes.name}
      >
        <ListItemText primary={name} />
        <ListItemIcon className={isCollapseOpen === name ? 'up' : 'down'}>
          <IconArrow />
        </ListItemIcon>
      </ListItem>

      <Collapse in={isCollapseOpen === name} timeout="auto" unmountOnExit>
        {items.map((item) => (
          <ListItem
            button
            component="a"
            href={item[1]}
            className={classes.item}
          >
            <ListItemText primary={item[0]} />
          </ListItem>
        ))}
      </Collapse>
    </>
  );

  return (
    <>
      <List className={classes.list}>
        {renderOption(
          'Institucional',
          [
            ['Quem somos', '/quem-somos/'],
            ['Blog', 'https://conube.com.br/blog/'],
            ['Trabalhe conosco', 'https://atracaodetalentos.totvs.app/carreiramindgroup/extended#listJobOpportunities'],
            ['Mapa do site', '/mapa-do-site/'],
          ]
        )}

        {renderOption(
          'Serviços',
          [
            ['Abrir empresa', '/abrir-empresa/'],
            ['Trocar de contador', '/trocar-contador/'],
            ['Quanto custa?', '/quanto-custa/'],
          ]
        )}

        {/* {renderOption(
          'Segurança',
          [
            // TODO: Colocar link do Registro no CRC
            ['Registro no CRC', '/'],
            // TODO: Colocar link da Política de privacidade
            ['Política de privacidade', '/'],
            // TODO: Colocar link dos Termos de uso
            ['Termos de uso', '/'],
          ]
        )} */}
      </List>
    </>
  );
};

export default Footer;
